import { magasinLink, servicesLinks } from './menu'
import { reparationsLinks } from './menu.context'

export type IdentityItem = {
  url: string
  image: string
  alt: string
  title: string
  subtitle: string
  subtitleMobile: string
  position: string
  buttonLabel: string
  buttonLabelMobile?: string
}

export type HomeSliders = IdentityItem[]

const HomeSlider: HomeSliders = [
  {
    image: 'Slider-reparation',
    url: reparationsLinks.all,
    alt: 'reparation-image',
    title: '-10€ sur la réparation de votre écran !',
    subtitle:
      "Un problème avec votre smartphone ?\nN'attendez plus pour le faire réparer !",
    subtitleMobile:
      "Un problème avec votre smartphone ? N'attendez plus pour le faire réparer !",
    position: 'left',
    buttonLabel: 'Je répare ! ',
  },
  {
    image: 'Slider-saveco',
    url: magasinLink,
    alt: 'saveco-image',
    title: 'Faites appel au leader\n de la réparation en France',
    subtitle:
      'Avec près de 200 magasins,\n il y en a forcément un près de chez vous',
    subtitleMobile:
      'Avec près de 200 magasins, il y en a forcément un près de chez vous',
    position: 'right',
    buttonLabel: 'Je trouve mon magasin',
  },
  {
    image: 'Slider-tarifs',
    url: reparationsLinks.all,
    alt: 'tarifs-image',
    title: 'Votre batterie ne tient plus la journée',
    subtitle: "Remplacez-la en moins d'une heure\n près de chez vous !",
    subtitleMobile: "Remplacez-la en moins d'une\n heure près de chez vous !",
    position: 'left',
    buttonLabel: 'Voir les tarifs',
  },
  {
    image: 'Slider-recover',
    url: servicesLinks.recover,
    alt: 'recover-image',
    title: 'Protection reCover',
    subtitle:
      'Découpée sur-mesure et disponible pour 100%\n des smartphones du marché',
    subtitleMobile:
      'Découpée sur-mesure et disponible pour 100%\n des smartphones !',
    position: 'right',
    buttonLabel: 'Je protège mon smartphone',
    buttonLabelMobile: 'Je découvre',
  },
  {
    image: 'Slider-reconditionne',
    url: servicesLinks.reconditionnes,
    alt: 'reconditionne-image',
    title: 'Smartphones reconditionnés Save',
    subtitle:
      'Découvrez nos smartphones reconditionnés préparés\n pour vous par nos experts SAVE',
    subtitleMobile: 'Découvrez nos smartphones reconditionnés SAVE',
    position: 'left',
    buttonLabel: 'Voir les reconditionnés',
    buttonLabelMobile: 'Voir les reconditionnés',
  },
  {
    image: 'Slider-orange',
    url: servicesLinks.reparationOrange,
    alt: 'reconditionne-image',
    title: 'Client Orange ?',
    subtitle:
        'Bénéficiez de -20% sur toutes \n vos réparations !',
    subtitleMobile: 'Bénéficiez de -20% sur toutes vos réparations !',
    position: 'right',
    buttonLabel: 'Obtenir un devis gratuit !',
    buttonLabelMobile: 'Obtenir un devis gratuit !',
  },
]

export default HomeSlider
